import { useTheme } from "@mui/material";
import { TRACK_EVENTS } from "core/consts";
import ConnectedChipGroup, {
  ChipGroupPresenterProps,
} from "ds_legacy/components/ChipGroup";
import RSButton from "ds_legacy/components/RSButton";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_SEMI_BOLD,
} from "ds_legacy/materials/typography";
import { usePageMetaData } from "dsl/hooks";
import { useGetProviderSearchRedirectLink } from "dsl/hooks/useGetProviderSearchRedirectLink";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { getProviderSearchTitle } from "dsl/molecules/ProviderSearchAppWrapper";
import { ProviderSearchFilters } from "dsl/organisms/Filters/ProviderSearchApp/types";
import {
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
} from "dsl/organisms/Filters/consts";
import { Iterable } from "immutable";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import {
  FormStepWrapper,
  FunnelFormData,
  FunnelSteps,
  STEP_CONSULTANT_PROVIDER_SELECTION,
  STEP_SOLUTIONS,
  STEP_ZIPCODE,
} from "../common";

const WITH_PROVIDERS = 1;
const WITH_CONSULTANTS = 2;

type ConsultantProviderSelection = Pick<
  ProviderSearchFilters,
  "with_consultants" | "with_providers"
>;

const modelDefinition = convertModelDefinition({
  ...valueDef("consultant_provider_selection", {
    validate(value, { translations }) {
      const validateValue = Iterable.isIterable(value) ? value.toJS() : value;
      return validateValue?.length
        ? true
        : {
            customMessage:
              translations.providersearch.onboardingFunnel.firstStep.error,
          };
    },
    convertIn: (input) => {
      if (!input) return null;
      const inputValue = Iterable.isIterable(input) ? input.toJS() : input;
      const result = [];

      if (inputValue.with_providers) {
        result.push(WITH_PROVIDERS);
      }
      if (inputValue.with_consultants) {
        result.push(WITH_CONSULTANTS);
      }

      return result;
    },
    convertOut: (output) => {
      if (!output) return null;
      const outputValue = Iterable.isIterable(output) ? output.toJS() : output;
      const selectedSearchType: Record<string, boolean> = {};

      selectedSearchType[USER_FILTER_WITH_PROVIDERS] =
        outputValue.includes(WITH_PROVIDERS);
      selectedSearchType[USER_FILTER_WITH_CONSULTANTS] =
        outputValue.includes(WITH_CONSULTANTS);

      return selectedSearchType;
    },
  }),
});

export function ConsultantProviderSelection({
  formData,
  onSubmit,
  setCurrentStep,
  setFormData,
}: {
  formData: FunnelFormData;
  onSubmit: (data: FunnelFormData) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<FunnelSteps>>;
  setFormData: React.Dispatch<React.SetStateAction<FunnelFormData>>;
}) {
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const getRedirectLink = useGetProviderSearchRedirectLink();
  const theme = useTheme();

  usePageMetaData({
    title: (translations) =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.searchForTitle,
      ),
    description: (translations) =>
      translations.providersearch.pageTitles.searchForDescription,
  });

  const options: ChipGroupPresenterProps["options"] = [
    {
      name: translations.providersearch.onboardingFunnel.firstStep.providers,
      value: WITH_PROVIDERS,
    },
    {
      name: translations.providersearch.onboardingFunnel.firstStep.consultants,
      value: WITH_CONSULTANTS,
    },
  ];

  return (
    <SimpleFormRenderProps
      asHtmlForm
      formInputValue={{
        // needs to be added manually to match the valueDef, otherwise the input would be null
        consultant_provider_selection: {
          [USER_FILTER_WITH_CONSULTANTS]: formData.with_consultants,
          [USER_FILTER_WITH_PROVIDERS]: formData.with_providers,
        },
      }}
      modelDefinition={modelDefinition}
      onSubmit={({
        consultant_provider_selection: { with_consultants, with_providers },
      }: {
        consultant_provider_selection: ConsultantProviderSelection;
      }) => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_CONSULTANT_PROVIDER_SELECTED,
          with_consultants,
          with_providers,
        });

        if (with_consultants && !with_providers) {
          return onSubmit({ with_consultants, with_providers });
        }

        setFormData({
          ...formData,
          with_consultants,
          with_providers,
        });
        setCurrentStep(STEP_SOLUTIONS);
      }}
    >
      {({ submit }) => {
        return (
          <FormStepWrapper
            formData={formData}
            title={
              translations.providersearch.onboardingFunnel.firstStep.question
            }
            skipLink={getRedirectLink({
              formData: {
                ...formData,
                with_consultants: undefined,
                with_providers: undefined,
              },
            })}
            step={STEP_CONSULTANT_PROVIDER_SELECTION}
            testId="consultant_provider_selection-select-wrapper"
          >
            <>
              <AsteriskExplained
                customMargin={margin(0, 0, 1.5, 0)}
                style={{
                  color: theme.palette.grey[600],
                  fontWeight: FONT_WEIGHT_SEMI_BOLD,
                }}
              />
              <ConnectedChipGroup
                elementName="consultant_provider_selection"
                chipStyle={{
                  height: sizing(6),
                  justifyContent: "center",
                  width: "100%",
                }}
                formGroupSx={{
                  flexDirection: "column",
                  margin: margin(1, 0),
                }}
                formHelperTextSx={{ margin: margin(0.5, 0, 0) }}
                formLabelSx={{
                  fontWeight: FONT_WEIGHT_BOLD,
                }}
                title={
                  translations.providersearch.onboardingFunnel.firstStep
                    .subtitle
                }
                squared
                required
                options={options}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  padding: padding(3, 0, 2),
                  gap: sizing(2),
                }}
              >
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                  }}
                  style={{ width: "100%", margin: margin(0) }}
                  color="primary"
                  id="go-back-on-consultant-providers-step"
                  loading="na"
                  type="button"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      with_consultants: undefined,
                      with_providers: undefined,
                    });
                    setCurrentStep(STEP_ZIPCODE);
                  }}
                  variant="outlined"
                >
                  {translations.providersearch.onboardingFunnel.backButton}
                </RSButton>
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                  }}
                  style={{ width: "100%", margin: margin(0) }}
                  color="primary"
                  id="confirm-consultants-providers"
                  loading="na"
                  type="submit"
                  onClick={submit}
                  variant="contained"
                >
                  {translations.providersearch.onboardingFunnel.nextButton}
                </RSButton>
              </div>
            </>
          </FormStepWrapper>
        );
      }}
    </SimpleFormRenderProps>
  );
}
