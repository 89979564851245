import { useTheme } from "@mui/material";
import { useLazyGetAddressFromZipcode } from "apollo/hooks/queries";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import {
  ALGOLIA_INDICES,
  ALGOLIA_SEARCH_ZIP_CODES,
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_TERMS_AND_CONDITIONS,
  TRACK_EVENTS,
} from "core/consts";
import { AlgoliaComboBox } from "ds_legacy/components/ComboBox";
import LinkV2, { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import RSButton from "ds_legacy/components/RSButton";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_12,
  FONT_SIZE_20,
  FONT_SIZE_28,
  FONT_WEIGHT_SEMI_BOLD,
  LINE_HEIGHT_24,
  LINE_HEIGHT_34,
} from "ds_legacy/materials/typography";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { ZipcodeHit, getAlgoliaZipcodeLabel } from "dsl/atoms/ZipcodesSelect";
import {
  FormStepWrapper,
  FunnelFormData,
  FunnelSteps,
  STEP_CONSULTANT_PROVIDER_SELECTION,
  STEP_ZIPCODE,
  StyledTitle,
} from "dsl/ecosystems/ProviderSearchOnboarding/common";
import { usePageMetaData } from "dsl/hooks";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { getProviderSearchTitle } from "dsl/molecules/ProviderSearchAppWrapper";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

const modelDefinition = convertModelDefinition({
  ...valueDef("zipcode", {
    fieldRequired: true,
    validate(value, { translations }) {
      return value
        ? true
        : {
            customMessage:
              translations.providersearch.auctionCreationModal
                .warningZipCodeMissing,
          };
    },
  }),
});

export function Zipcode({
  formData,
  setCurrentStep,
  setFormData,
}: {
  formData: FunnelFormData;
  setCurrentStep: React.Dispatch<React.SetStateAction<FunnelSteps>>;
  setFormData: React.Dispatch<React.SetStateAction<FunnelFormData>>;
}) {
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const { config } = useProviderSearchContext();
  const externalLinkLabel = useExternalLinkLabel();
  const getZipcode = useLazyGetAddressFromZipcode();
  const { getDocumentUrl } = useLegalDocuments();
  const theme = useTheme();
  const { isDesktop } = useMedia();

  usePageMetaData({
    title: (translations) =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.onboardingFunnelTitle,
      ),
    description: (translations) =>
      translations.providersearch.pageTitles.onboardingFunnelDescription,
  });

  return (
    <SimpleFormRenderProps
      asHtmlForm
      formInputValue={formData}
      modelDefinition={modelDefinition}
      onSubmit={({ zipcode } = {}) => {
        if (zipcode?.value) {
          getZipcode(zipcode.value)
            .then((address) => {
              if (!address) {
                throw new Error(
                  `Couldn't find address for given zipcode ${zipcode.value}`,
                );
              }

              trackEvent({
                name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_ZIPCODE_SELECTED,
                zipcode: zipcode.value,
              });

              setFormData({
                ...formData,
                zipcode: {
                  ...address,
                  label: `${address.zipcode}, ${address.city}, ${address.federal_state}`,
                  value: address.zipcode!,
                },
              });
              setCurrentStep(STEP_CONSULTANT_PROVIDER_SELECTION);
            })
            .catch((error) => {
              console.error(
                "Failed to getZipcode in ProviderSearchZipcode:",
                error,
              );
            });
        }
      }}
    >
      {({ submit }) => {
        return (
          <FormStepWrapper
            formData={formData}
            step={STEP_ZIPCODE}
            testId="zipcode-select-wrapper"
          >
            <StyledTitle
              sx={{
                margin: margin(0, 0, 1),
                fontSize: isDesktop ? FONT_SIZE_28 : FONT_SIZE_20,
                color: theme.palette.primary.main,
                lineHeight: isDesktop ? LINE_HEIGHT_34 : LINE_HEIGHT_24,
              }}
            >
              <span style={{ display: "block" }}>
                {translations.providersearch.startPage.title}
              </span>
              <span style={{ display: "block" }}>
                {translations.providersearch.startPage.subtitle}
              </span>
            </StyledTitle>
            <Body
              as="p"
              margin={margin(0, 0, 1)}
              color={theme.palette.grey[600]}
            >
              {translations.providersearch.startPage.paragraphOne}
            </Body>
            <Body
              as="p"
              margin={margin(0, 0, 3)}
              color={theme.palette.grey[600]}
            >
              {translations.providersearch.startPage.paragraphTwo}
            </Body>
            <StyledTitle as="h2" sx={{ margin: margin(0, 0, 1) }}>
              {translations.providersearch.listingPage.zipCodeModal.title}
            </StyledTitle>
            <VerticalLayout margin={margin(0)} padding={padding(0, 0.25)}>
              <Body
                as="p"
                margin={margin(0, 0, 3)}
                color={theme.palette.grey[600]}
              >
                {translations.providersearch.listingPage.zipCodeModal.body}
              </Body>
              <AsteriskExplained
                customMargin={margin(0, 0, 1.5)}
                style={{
                  color: theme.palette.grey[600],
                  fontWeight: FONT_WEIGHT_SEMI_BOLD,
                }}
              />
              <AlgoliaComboBox<ZipcodeHit, { value: string }>
                algoliaAnalyticsName={ALGOLIA_SEARCH_ZIP_CODES}
                allowsEmptyCollection
                connected
                elementName="zipcode"
                filters={
                  config.allowedFederalState
                    ? `federal_state:${config.allowedFederalState}`
                    : `country:DE`
                }
                getOptionLabel={getAlgoliaZipcodeLabel}
                getValue={({ code }) => ({ value: code })}
                indexName={ALGOLIA_INDICES.ZIPCODE}
                isRequired
                label={translations.bToC.filterDrawer.searchRadiusPlaceholder}
                noOptionsText={translations.bToC.noResultsWithState({
                  state: config.allowedFederalState,
                })}
                placeholder={
                  translations.bToC.filterDrawer.searchRadiusPlaceholder
                }
                wrapperSx={{ marginBottom: dp(12) }}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  padding: padding(2, 0),
                  boxSizing: "border-box",
                }}
              >
                <RSButton
                  buttonWrapperStyle={{
                    width: "100%",
                  }}
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    margin: margin(0),
                  }}
                  color="primary"
                  id="confirm-zipcode"
                  loading="na"
                  type="submit"
                  onClick={submit}
                  variant="contained"
                >
                  {
                    translations.providersearch.listingPage.zipCodeModal
                      .startButton
                  }
                </RSButton>
              </div>
              <LinkV2
                aria-label={externalLinkLabel(
                  translations.providersearch.listingPage.zipCodeModal
                    .privacyPolicy,
                  "pdf",
                )}
                onClick={() =>
                  trackEvent({
                    name: TRACK_EVENTS.PROVIDER_SEARCH_PRIVACY_POLICY_CLICKED,
                  })
                }
                fontSize={FONT_SIZE_12}
                color="primary"
                href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
                sx={{ margin: margin(0.5, 0, 0), alignSelf: "center" }}
                target="_blank"
              >
                {
                  translations.providersearch.listingPage.zipCodeModal
                    .privacyPolicy
                }
              </LinkV2>
              <LinkV2
                aria-label={externalLinkLabel(
                  translations.providersearch.listingPage.zipCodeModal
                    .termsOfUse,
                  "pdf",
                )}
                onClick={() =>
                  trackEvent({
                    name: TRACK_EVENTS.PROVIDER_SEARCH_TERMS_OF_USE_CLICKED,
                  })
                }
                fontSize={FONT_SIZE_12}
                color="primary"
                href={getDocumentUrl(DOCUMENT_TERMS_AND_CONDITIONS)}
                sx={{ margin: margin(0.5, 0), alignSelf: "center" }}
                target="_blank"
              >
                {
                  translations.providersearch.listingPage.zipCodeModal
                    .termsOfUse
                }
              </LinkV2>
            </VerticalLayout>
          </FormStepWrapper>
        );
      }}
    </SimpleFormRenderProps>
  );
}
